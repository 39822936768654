
import React, { useEffect, useState, useHistory } from 'react'
import { Button, Container, Form } from 'react-bootstrap'
import logo from '../Assets/logo.jpeg'

const Home = () => {

    const [its, setIts] = useState()
    const [itsps, setItsps] = useState()
  

    const onHandleItsChange = (e) => {
        setIts(e.target.value)
    }
    const onHandleItsPsChange = (e) => {
        setItsps(e.target.value)
    }

    const onHandleSubmit = (e) => {
       e.preventDefault()
        const itsArray = ['30445411', '30600979', '20412724', '30446514', '30499041', '30378354', '40400687', '40400502', '20356905', '20356927', '40400699'];
        const itsAudio = ['40400703', '30445430']
        if (itsArray.includes(its) && its === itsps) {
            localStorage.setItem('its', its);
            window.location.replace("/asharamubaraka/1446/relay/video")
            } else if(itsAudio.includes(its) && its === itsps) {
                window.location.replace("/asharamubaraka/1446/relay/audio")
           }else {
            alert('ITS & Password is incorrect');
           }
    }


    return (
        <>
        <Container style={{ marginTop: 200, alignItems: 'center'}}>
            <div style={{ display: 'flex', alignItems: 'center', margin: 'auto', justifyContent: 'center', backgroundColor: "White", padding: 40}}>
            <Form style={{ width: 400}} onSubmit={onHandleSubmit}>
                <img src={logo} style={{ width: 100}}/>
  <Form.Group controlId="formBasicEmail" style={{ marginTop: 10 }}>
    <Form.Label >ITS</Form.Label>
    <Form.Control type="text" placeholder="Enter ITS Number" onChange={onHandleItsChange}/>
  </Form.Group>

  <Form.Group controlId="formBasicPassword" style={{ marginTop: 10 }}>
    <Form.Label >Password</Form.Label>
    <Form.Control type="password" placeholder="Password" onChange={onHandleItsPsChange} />
  </Form.Group>
  <Button variant="warning" type="submit" style={{ marginTop: 30 }} >
    Login
  </Button>
</Form>
            </div>

        </Container>
        

        </>
    )
}

export default Home
