import React from 'react'
import VideoStreaming from '../Component/VideoStreaming'
import { BrowserRouter, Route, Router, Routes } from 'react-router-dom';
import Home from '../Component/Login';
import AudioStreaming from '../Component/AudioStreaming';


const Main = () => {

    const itsValue = localStorage.getItem('its');

    return (
      <BrowserRouter>
        <Routes>
            <Route path="/asharamubaraka/1446/relay/video" element={<VideoStreaming />} />
            <Route path="/asharamubaraka/1446/relay/audio" element={<AudioStreaming />} />
            <Route path="/" exact element={<Home />} />
        </Routes>
      </BrowserRouter>
    );
  
}

export default Main
